import React from "react";

export default function InputField(props) {
  return (
    <div className="form-wrapper">
      <label className={props.required ? "required" : null}>
        {props.label}
      </label>
      <div className="form-wrapper__input">
        <input
          type={props.type ? props.type : "text"}
          placeholder={props.placeholder}
          name={props.name}
          value={props.value}
          onChange={props.handleChange}
          // required={props.required}
          className={props.error && "hasError"}
        />
        {props.error && <p className="form-input__error">{props.error}</p>}
      </div>
    </div>
  );
}
