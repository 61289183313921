import React from "react";
import Spinner from "../Spinner/Spinner";
import InputField from "../Form/InputField";
import runtimeEnv from '@mars/heroku-js-runtime-env';
const env = runtimeEnv();
const REACT_APP_FINBE_USERNAME = env.REACT_APP_FINBE_USERNAME;
const REACT_APP_FINBE_PASSWORD = env.REACT_APP_FINBE_PASSWORD;

class SignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      successMessage: null,
      errorMessage: null,
      loading: false,
      requestErrors: {},
      requestBody: {
        username: {
          value: "",
          required: true
        },
        password: {
          value: "",
          required: true
        },
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleChange(event) {
    const requestBody = { ...this.state.requestBody };
    requestBody[event.target.name].value = event.target.value;
    this.setState({ requestBody });
  }

  onSubmit = async event => {
    event.preventDefault();
    const { requestBody, loading } = this.state;
    if (loading) {
      return false;
    }
    if (!this.validateForm()) {
      return false;
    }
    this.setState({ loading: true });
    let username = requestBody["username"].value;
    let password = requestBody["password"].value
    let usernames = REACT_APP_FINBE_USERNAME.split(',')
    let passwords = REACT_APP_FINBE_PASSWORD.split(',')
    let index = usernames.indexOf(username);
    if (passwords[index] == password) {
      this.props.onValidate(true, username);
    } else {
      this.setState({
        errorMessage: "El usuario o contraseña son incorrectas",
        loading: false
      });
    }
  };

  validateForm = () => {
    const { requestBody } = this.state;
    const requestErrors = {};

    Object.keys(requestBody).forEach(field => {
      let { required, value, format } = requestBody[field];

      if (required) {
        if (value === "") {
          requestErrors[field] = "Este campo es obligatorio";
        } else if (format) {
          if (!format.test(value)) {
            requestErrors[field] = "Campo no valido";
          }
        }
      } else {
        if (value && format) {
          if (!format.test(value)) {
            requestErrors[field] = "Campo no validos";
          }
        }
      }
    });

    if (Object.keys(requestErrors).length > 0) {
      this.setState({
        requestErrors
      });
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {
      requestBody,
      loading,
      requestErrors,
      successMessage,
      errorMessage
    } = this.state;

    if (this.props.hide) {
      return null
    }

    let error = errorMessage ? (
      <div className="error">{this.state.errorMessage}</div>
    ) : (
      ""
    );

    return (
      <div className="container">
        {successMessage ? (
          <div className="success">
            <p>La consulta se ha realizado exitosamente</p>
          </div>
        ) : (
          <form onSubmit={this.onSubmit}>
            {error}
            <h2>Consulta BNC</h2>

            <h4>Datos de Acceso</h4>
            <InputField
              label="Usuario"
              placeholder="Usuario"
              name="username"
              value={requestBody.username.value}
              handleChange={this.handleChange}
              required={requestBody.username.required}
              error={requestErrors["username"] || null}
            />

            <InputField
              label="Contraseña"
              placeholder="Contraseña"
              name="password"
              type="password"
              value={requestBody.password.value}
              handleChange={this.handleChange}
              required={requestBody.password.required}
              error={requestErrors["password"] || null}
            />

            <button className={loading ? "submitting" : null} type="submit">
              {loading ? (
                <React.Fragment>
                  <Spinner /> <span>Enviando...</span>
                </React.Fragment>
              ) : (
                "Enviar"
              )}
            </button>
          </form>
        )}
      </div>
    );
  }
}

export default SignUp;
