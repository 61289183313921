import React from "react";
import axios from "axios";
import Spinner from "../Spinner/Spinner";
import InputField from "../Form/InputField";
import SelectField from "../Form/SelectField";
import runtimeEnv from '@mars/heroku-js-runtime-env';
const env = runtimeEnv();
const REACT_APP_FINBE_CREDIT_BUREAU_CONSULT = env.REACT_APP_FINBE_CREDIT_BUREAU_CONSULT;

const states_options = [
  { name: 'Aguascalientes', value: 'AGS' },
  { name: 'Baja California', value: 'BCN' },
  { name: 'Baja California Sur', value: 'BCS' },
  { name: 'Campeche', value: 'CAM' },
  { name: 'Coahuila', value: 'COA' },
  { name: 'Colima', value: 'COL' },
  { name: 'Chiapas', value: 'CHS' },
  { name: 'Chihuahua', value: 'CHI' },
  { name: 'Ciudad de México', value: 'CDMX' },
  { name: 'Durango', value: 'DGO' },
  { name: 'Guanajuato', value: 'GTO' },
  { name: 'Guerrero', value: 'GRO' },
  { name: 'Hidalgo', value: 'HGO' },
  { name: 'Jalisco', value: 'JAL' },
  { name: 'Estado de México', value: 'EM' },
  { name: 'Michoacan', value: 'MICH' },
  { name: 'Morelos', value: 'MOR' },
  { name: 'Nayarit', value: 'NAY' },
  { name: 'Nuevo León', value: 'NL' },
  { name: 'Oaxaca', value: 'OAX' },
  { name: 'Puebla', value: 'PUE' },
  { name: 'Querétaro', value: 'QRO' },
  { name: 'Quintana Roo', value: 'QR' },
  { name: 'San Luis Potosí', value: 'SLP' },
  { name: 'Sinaloa', value: 'SIN' },
  { name: 'Sonora', value: 'SON' },
  { name: 'Tabasco', value: 'TAB' },
  { name: 'Tamaulipas', value: 'TAM' },
  { name: 'Tlaxcala', value: 'TLAX' },
  { name: 'Veracruz', value: 'VER' },
  { name: 'Yucatán', value: 'YUC' },
  { name: 'Zacatecas', value: 'ZAC' }
]

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      successMessage: null,
      errorMessage: null,
      loading: false,
      requestErrors: {},
      requestBody: {
        name: {
          value: "",
          required: true,
          format: /^[\w',.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*()\{\}|~<>;:[\]]+$/
        },
        middle_name: {
          value: "",
          required: false,
          format: /^[\w',.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*()\{\}|~<>;:[\]]+$/
        },
        paternal_lastname: {
          value: "",
          required: true,
          format: /^[\w',.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*()\{\}|~<>;:[\]]+$/
        },
        maternal_lastname: {
          value: "",
          required: false,
          format: /^[\w',.][^0-9_!¡?÷?¿/\\+=@#$%ˆ&*()\{\}|~<>;:[\]]+$/
        },
        birthday_day: {
          value: "",
          required: true,
          format: ""
        },
        birthday_month: {
          value: "",
          required: true,
          format: ""
        },
        birthday_year: {
          value: "",
          required: true,
          format: ""
        },
        rfc: {
          value: "",
          required: true,
          format: /^([A-Z]{4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])([A-Z|\d]{3})?)$/
        },
        street: {
          value: "",
          required: true,
          format: ""
        },
        exterior_number: {
          value: "",
          required: true,
          format: ""
        },
        neighborhood: {
          value: "",
          required: true,
          format: ""
        },
        postal_code: {
          value: "",
          required: true,
          format: /^(\d{5})?$/
        },
        city: {
          value: "",
          required: true,
          format: ""
        },
        state: {
          value: "",
          required: true,
          format: ""
        },
        email: {
          value: "",
          required: true,
          format: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        },
        phone: {
          value: "",
          required: false,
          format: /^\d{7}(?:\d{1})?$/
        },
        cellphone_lada: {
          value: "+52",
          required: true,
          format: ""
        },
        cellphone: {
          value: "",
          required: true,
          format: /^[0-9]{10}$/
        },
        executive_email: {
          value: "",
          required: true,
          format: /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
        }
      }
    };

    this.handleChange = this.handleChange.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleChange(event) {
    const requestBody = { ...this.state.requestBody };
    requestBody[event.target.name].value = event.target.value;
    this.setState({ requestBody });
  }

  onSubmit = async event => {
    event.preventDefault();
    const { requestBody, loading } = this.state;
    if (loading) {
      return false;
    }
    if (!this.validateForm()) {
      return false;
    }
    this.setState({ loading: true });

    const body = {};

    Object.keys(requestBody).forEach(field => {
      body[field] = requestBody[field].value;
    });

    const date_of_birth = new Date(
      requestBody["birthday_year"].value,
      requestBody["birthday_month"].value,
      requestBody["birthday_day"].value
    );

    const phone = requestBody["phone"].value
      ? requestBody["phone"].value.replace(/\D/g, "")
      : "";
    const cellphone = requestBody["cellphone"].value.replace(/\D/g, "");

    body["date_of_birth"] = Math.floor(date_of_birth.getTime() / 1000);
    body["mobile_phone"] = requestBody["cellphone_lada"].value + cellphone;
    body["phone"] = phone;
    body["username"] = this.props.username

    try {
      const { data, status } = await axios.post(
        `${REACT_APP_FINBE_CREDIT_BUREAU_CONSULT}`,
        body
      );

      if (status !== 200) {
        throw new Error(data.title);
      }
      this.setState({
        successMessage: true,
        loading: false
      });
    } catch (err) {
      this.setState({
        errorMessage: err,
        loading: false
      });
    }
  };

  validateForm = () => {
    const { requestBody } = this.state;
    const requestErrors = {};

    Object.keys(requestBody).forEach(field => {
      let { required, value, format } = requestBody[field];

      if (field === "cellphone" || field === "phone") {
        value = value.replace(/\D/g, "");
      }

      if (required) {
        if (value === "") {
          requestErrors[field] = "Este campo es obligatorio";
        } else if (format) {
          if (!format.test(value)) {
            requestErrors[field] = "Campo no valido";
          }
        }
      } else {
        if (value && format) {
          if (!format.test(value)) {
            requestErrors[field] = "Campo no validos";
          }
        }
      }
    });

    if (Object.keys(requestErrors).length > 0) {
      this.setState({
        requestErrors
      });
      return false;
    } else {
      return true;
    }
  };

  render() {
    const {
      requestBody,
      loading,
      requestErrors,
      successMessage,
      errorMessage
    } = this.state;
    if (this.props.hide) {
      return null
    }

    let error = errorMessage ? (
      <div className="error">{this.state.errorMessage.title}</div>
    ) : (
      ""
    );

    return (
      <div className="container">
        {successMessage ? (
          <div className="success">
            <p>La consulta se ha realizado exitosamente</p>
          </div>
        ) : (
          <form onSubmit={this.onSubmit}>
            {error}
            <h2>Consulta BNC</h2>

            <h4>Datos Personales</h4>
            <InputField
              label="Nombre"
              placeholder="Nombre"
              name="name"
              value={requestBody.name.value}
              handleChange={this.handleChange}
              required={requestBody.name.required}
              error={requestErrors["name"] || null}
            />

            <InputField
              label="Segundo nombre"
              placeholder="Segundo Nombre (Opcional)"
              name="middle_name"
              value={requestBody.middle_name.value}
              handleChange={this.handleChange}
              required={requestBody.middle_name.required}
              error={requestErrors["middle_name"] || null}
            />

            <InputField
              label="Apellido paterno"
              placeholder="Apellido paterno"
              name="paternal_lastname"
              value={requestBody.paternal_lastname.value}
              handleChange={this.handleChange}
              required={requestBody.paternal_lastname.required}
              error={requestErrors["paternal_lastname"] || null}
            />

            <InputField
              label="Apellido materno"
              placeholder="Apellido materno"
              name="maternal_lastname"
              value={requestBody.maternal_lastname.value}
              handleChange={this.handleChange}
              required={requestBody.maternal_lastname.required}
              error={requestErrors["maternal_lastname"] || null}
            />

            <div className="form-wrapper">
              <label className="required">Fecha de nacimiento</label>
              <SelectField
                name="birthday_day"
                value={requestBody.birthday_day.value}
                required={requestBody.birthday_day.required}
                handleChange={this.handleChange}
                label="Dia"
                options={[...Array(31).keys()].map(day => ({
                  value: day + 1,
                  name: day + 1
                }))}
                error={requestErrors["birthday_day"] || null}
              />

              <SelectField
                name="birthday_month"
                value={requestBody.birthday_month.value}
                required={requestBody.birthday_month.required}
                handleChange={this.handleChange}
                label="Mes"
                options={[
                  "Enero",
                  "Febrero",
                  "Marzo",
                  "Abril",
                  "Mayo",
                  "Junio",
                  "Julio",
                  "Agosto",
                  "Septiembre",
                  "Octubre",
                  "Noviembre",
                  "Diciembre"
                ].map((month, index) => ({ value: index + 1, name: month }))}
                error={requestErrors["birthday_month"] || null}
              />

              <SelectField
                name="birthday_year"
                value={requestBody.birthday_year.value}
                required={requestBody.birthday_year.required}
                handleChange={this.handleChange}
                label="Año"
                options={[...Array(100).keys()].map(num => {
                  const year = new Date().getFullYear() - 18;
                  return { value: year - num, name: year - num };
                })}
                error={requestErrors["birthday_year"] || null}
              />
            </div>

            <InputField
              label="RFC"
              placeholder="Ingrese su RFC"
              name="rfc"
              value={requestBody.rfc.value}
              handleChange={this.handleChange}
              required={requestBody.rfc.required}
              error={requestErrors["rfc"] || null}
            />

            <h4>Direccion</h4>
            <InputField
              label="Calle"
              placeholder="Calle"
              name="street"
              value={requestBody.street.value}
              handleChange={this.handleChange}
              required={requestBody.street.required}
              error={requestErrors["street"] || null}
            />

            <InputField
              label="Numero"
              placeholder="Numero"
              name="exterior_number"
              value={requestBody.exterior_number.value}
              handleChange={this.handleChange}
              required={requestBody.exterior_number.required}
              error={requestErrors["exterior_number"] || null}
            />

            <InputField
              label="Colonia"
              placeholder="Colonia"
              name="neighborhood"
              value={requestBody.neighborhood.value}
              handleChange={this.handleChange}
              required={requestBody.neighborhood.required}
              error={requestErrors["neighborhood"] || null}
            />

            <InputField
              label="C.P"
              placeholder="C.P"
              name="postal_code"
              value={requestBody.postal_code.value}
              handleChange={this.handleChange}
              required={requestBody.postal_code.required}
              error={requestErrors["postal_code"] || null}
            />

            <InputField
              label="Ciudad"
              placeholder="Ciudad"
              name="city"
              value={requestBody.city.value}
              handleChange={this.handleChange}
              required={requestBody.city.required}
              error={requestErrors["city"] || null}
            />

            <div className="form-wrapper">
              <label className="required">Estado</label>
              <SelectField
                label="Estado"
                placeholder="Estado"
                name="state"
                value={requestBody.state.value}
                handleChange={this.handleChange}
                required={requestBody.state.required}
                error={requestErrors["state"] || null}
                options={states_options}
                error={requestErrors["birthday_year"] || null}
              />
            </div>

            <h4>Contacto</h4>
            <InputField
              label="Email"
              placeholder="Email"
              name="email"
              value={requestBody.email.value}
              handleChange={this.handleChange}
              required={requestBody.email.required}
              error={requestErrors["email"] || null}
            />

            <InputField
              label="Teléfono fijo"
              placeholder="(99) 11-11-11"
              name="phone"
              value={requestBody.phone.value}
              handleChange={this.handleChange}
              required={requestBody.phone.required}
              error={requestErrors["phone"] || null}
            />

            <div className="form-wrapper">
              <label className="required">Teléfono móvil</label>
              <div className="form-wrapper__input--small">
                <select
                  name="cellphone_lada"
                  value={requestBody.cellphone_lada.value}
                  onChange={this.handleChange}
                  required
                >
                  <option value="+52">+52</option>
                </select>
              </div>
              <div className="form-wrapper__input">
                <input
                  type="text"
                  placeholder="(9999) 11-11-11"
                  name="cellphone"
                  value={requestBody.cellphone.value}
                  onChange={this.handleChange}
                  required
                  className={requestErrors["cellphone"] && "hasError"}
                />
                {requestErrors["cellphone"] && (
                  <p className="form-input__error">
                    {requestErrors["cellphone"]}
                  </p>
                )}
              </div>
            </div>

            <h4>Ejecutivo</h4>
            <InputField
              label="Email ejecutivo"
              placeholder="Email ejecutivo"
              name="executive_email"
              value={requestBody.executive_email.value}
              handleChange={this.handleChange}
              required={requestBody.executive_email.required}
              error={requestErrors["executive_email"] || null}
            />

            <button className={loading ? "submitting" : null} type="submit">
              {loading ? (
                <React.Fragment>
                  <Spinner /> <span>Enviando...</span>
                </React.Fragment>
              ) : (
                "Enviar"
              )}
            </button>
          </form>
        )}
      </div>
    );
  }
}

export default SignUp;
