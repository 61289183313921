import React from "react";
import SignUp from "./components/SignUp/Signup";
import Password from "./components/Password/Password";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      permissions: false,
      username: ''
    };
    this.handleValidation = this.handleValidation.bind(this);
  }

  handleValidation(permissions, username) {
    this.setState({permissions, username});
  }

  render() {
    return (
      <div className="wrapper">
        <SignUp hide={!this.state.permissions} username={this.state.username} />
        <Password hide={this.state.permissions} onValidate={this.handleValidation} />
        <p className="terms-conditions">
          <a href="https://www.finbe.com.mx/SitePages/Aviso%20Legal.aspx">
            Terminos y condiciones
          </a>{" "}
          ·
          <a href="https://www.finbe.com.mx/SitePages/Aviso%20de%20Privacidad.aspx">
            {" "}
            Aviso de privacidad
          </a>
        </p>
      </div>
    );
  }
}

export default App;
