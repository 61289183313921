import React from "react";

export default function SelectField(props) {
  // console.log(`[SelectField] ${props.name}`, props.options);
  return (
    <div className="form-wrapper__input">
      <select
        name={props.name}
        value={props.value}
        onChange={props.handleChange}
        // required={props.required}
      >
        <option value="">{props.label}</option>
        {props.options.map((item, index) => (
          <option value={item.value} key={index}>
            {item.name}
          </option>
        ))}
      </select>
      {props.error && <p className="form-input__error">{props.error}</p>}
    </div>
  );
}
